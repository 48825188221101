<template>
  <div class="vb-result">
    <div v-if="products.length == 0" class="text-center">
      <p>
        Siamo spiacenti! Non ci sono risultati per la ricerca effettuata.<br>
        Prova a cambiare i criteri di ricerca.
      </p>
    </div>

    <b-row v-else>
      <b-col cols="12" class="text-center">
        <h1 class="vb-heading vb-heading--h1">{{location.label}}</h1>
        <p>Volo da
          <span
            class="text-capitalize"
            v-for="(airport, index) in airports_selected"
            v-bind:key="airport+index"
          >
            {{airport.name.toLowerCase()}} ({{airport.iata_code}})
          </span> -
          {{occupancy[0].adults + occupancy[0].children.length}} ospiti -
          {{parse_date(departure_date).day_number}} {{parse_date(departure_date).month_string}} {{parse_date(departure_date).year}}
          ({{nights}} notti)
        </p>
      </b-col>

      <b-col cols="12">
        <filters 
          class="mt-2 mb-3"
          v-if="!loading"
          :products="products"
          :filtered="filtered_products"
          :statics="statics"
          :sort="sort"
          :active_filters="{
            filter_keyd: filter_keyd,
            filter_accommodation: filter_accommodation,
            filter_rating: filter_rating,
            filter_airport: filter_airport,
            filter_brand: filter_brand,
            filter_availability: filter_availability,
          }"
          @order="$event => order($event)"
          @filter="$event => filter($event)"
          @reset="reset()"
        />
      </b-col>

      <!-- Content -->
      <b-col cols="12">
        <dynamic-scroller
          v-if="!loading"
          page-mode
          ref='results-scroller'
          :items="filtered_products"
          :item-size="null"
          :min-item-size="100"
          key-field="pidkey"
        >
          <template v-slot="{ item, index, active }">
            <dynamic-scroller-item
              class="pb-4"
              :item="item"
              :data-index="index"
              :active="active"
              :size-dependencies="[
                  //item.thumbNailUrl,
                  //item.shortDescription,
                ]"
            >
              <pad
                :product="item"
                :statics="statics[item.pid]"
                :occupancy="occupancy"
                :keyds="keyds"
                :brand="get_brand(item.pid.split('-')[1])"
              />
            </dynamic-scroller-item>
          </template>
        </dynamic-scroller>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { number } from 'mathjs'
import {parse_date, deep_clone, custom_event} from '@utilities/commons'
import { get_brand } from '@alpitour/resources'
import { markup } from '@alpitour/utilities'
import Filters from '@alpitour/components/blocks/filters'

export default {
  name: "results-block",
  props: {
  },
  components: {
    filters: Filters,
  },
  created() {
    this.parse_date = parse_date
    this.deep_clone = deep_clone
    this.markup = markup
    this.get_brand = get_brand
  },
  data() {
    return {
      location: this.$store.state.liveat.location,
      departure_airports: this.$store.state.liveat.departure_airports,
      occupancy: this.$store.state.occupancy, // deep_clone ?
      products: this.$store.state.liveat.products,
      statics: this.$store.state.liveat.statics,
      keyds: this.$store.state.liveat.keyds,
      departure_date: this.$store.state.liveat.departure_date,
      nights: this.$store.state.liveat.nights,
      loading: true,
      filtered_products: [],
    }
  },
  computed: {
    airports_selected: function () {
      return this.departure_airports.filter(a => {
        return a.selected
      })
    },
    filter_keyd: {
      get() {
        return this.$store.state.liveat.filter_keyd
      },
      set(value) {
        this.$store.commit('liveat/filter_keyd', value)
      }
    },
    filter_accommodation: {
      get() {
        return this.$store.state.liveat.filter_accommodation
      },
      set(value) {
        this.$store.commit('liveat/filter_accommodation', value)
      }
    },
    filter_rating: {
      get() {
        return this.$store.state.liveat.filter_rating
      },
      set(value) {
        this.$store.commit('liveat/filter_rating', value)
      }
    },
    filter_airport: {
      get() {
        return this.$store.state.liveat.filter_airport
      },
      set(value) {
        this.$store.commit('liveat/filter_airport', value)
      }
    },
    filter_brand: {
      get() {
        return this.$store.state.liveat.filter_brand
      },
      set(value) {
        this.$store.commit('liveat/filter_brand', value)
      }
    },
    filter_availability: {
      get() {
        return this.$store.state.liveat.filter_availability
      },
      set(value) {
        this.$store.commit('liveat/filter_availability', value)
      }
    },
    sort: {
      get() {
        return this.$store.state.liveat.sort
      },
      set(value) {
        this.$store.commit('liveat/sort', value)
      }
    }
  },
  mounted() {
    this.filter_products()
    this.loading = false

    if(this.products.length == 0) custom_event('no_results')

  },
  methods: {
    order($event) {
      this.sort = $event
      this.filter_products()
    },
    check_filter_results(items, key) {
      if (items.length == 0) {
        this.$nextTick(() => {
          this.filter({
            key: key,
            filters: [],
          })
        })
        return false
      }
      return true
    },
    filter($event) {
      this.$store.commit('liveat/filter_'+$event.key, $event.filters)
      this.filter_products()
    },
    reset() {
      this.$store.commit('liveat/filter_keyd', [])
      this.$store.commit('liveat/filter_accommodation', [])
      this.$store.commit('liveat/filter_rating', [])
      this.$store.commit('liveat/filter_airport', [])
      this.$store.commit('liveat/filter_brand', [])
      this.$store.commit('liveat/filter_availability', false)
      this.filter_products()
    },
    filter_products() {
      //this.loading = true
      this.filtered_products = this.products

      // filter availabilty
      if (this.filter_availability) {
        let availability = this.get_availability().total
        this.filtered_products = this.filtered_products.filter(p => {
          return markup(p.price).display_price <= availability
        })
      }

      // filter by keyd
      if (this.filter_keyd.length) this.filtered_products = this.filtered_products.filter(p => { return this.filter_keyd.includes(p.keyd) })

      // filter by accommodation
      if (this.filter_accommodation.length) {
        this.filtered_products = this.filtered_products.filter(p => { return this.filter_accommodation.includes(p.accommodation) })
        if (!this.check_filter_results(this.filtered_products, 'accommodation')) return
      }

      // filter by rating
      if (this.filter_rating.length) {
        this.filtered_products = this.filtered_products.filter(p => { return this.filter_rating.includes(p.rating) })
        if (!this.check_filter_results(this.filtered_products, 'rating')) return
      }

      // filter by airport
      if (this.filter_airport.length) {
        this.filtered_products = this.filtered_products.filter(p => { return this.filter_airport.includes(p.departureAirportCode) })
        if (!this.check_filter_results(this.filtered_products, 'airport')) return
      }

      // filter by brand
      if (this.filter_brand.length) {
        this.filtered_products = this.filtered_products.filter(p => { return this.filter_brand.includes(p.brand) })
        if (!this.check_filter_results(this.filtered_products, 'brand')) return
      }

      switch (this.sort) {
        case 'price|DESC':
          this.filtered_products.sort( (a,b) => number(b.price) - number(a.price) )
          break
      
        case 'price|ASC':
          this.filtered_products.sort( (a,b) => number(a.price) - number(b.price) )
          break
      
        default:
          break
      }
    }    
  }
}
</script>

<style lang="scss">

  .vb-result {

    .vb-heading--h1 {
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

</style>