var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vb-result"},[(_vm.products.length == 0)?_c('div',{staticClass:"text-center"},[_vm._m(0)]):_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"vb-heading vb-heading--h1"},[_vm._v(_vm._s(_vm.location.label))]),_c('p',[_vm._v("Volo da "),_vm._l((_vm.airports_selected),function(airport,index){return _c('span',{key:airport+index,staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(airport.name.toLowerCase())+" ("+_vm._s(airport.iata_code)+") ")])}),_vm._v(" - "+_vm._s(_vm.occupancy[0].adults + _vm.occupancy[0].children.length)+" ospiti - "+_vm._s(_vm.parse_date(_vm.departure_date).day_number)+" "+_vm._s(_vm.parse_date(_vm.departure_date).month_string)+" "+_vm._s(_vm.parse_date(_vm.departure_date).year)+" ("+_vm._s(_vm.nights)+" notti) ")],2)]),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('filters',{staticClass:"mt-2 mb-3",attrs:{"products":_vm.products,"filtered":_vm.filtered_products,"statics":_vm.statics,"sort":_vm.sort,"active_filters":{
          filter_keyd: _vm.filter_keyd,
          filter_accommodation: _vm.filter_accommodation,
          filter_rating: _vm.filter_rating,
          filter_airport: _vm.filter_airport,
          filter_brand: _vm.filter_brand,
          filter_availability: _vm.filter_availability,
        }},on:{"order":$event => _vm.order($event),"filter":$event => _vm.filter($event),"reset":function($event){return _vm.reset()}}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('dynamic-scroller',{ref:"results-scroller",attrs:{"page-mode":"","items":_vm.filtered_products,"item-size":null,"min-item-size":100,"key-field":"pidkey"},scopedSlots:_vm._u([{key:"default",fn:function({ item, index, active }){return [_c('dynamic-scroller-item',{staticClass:"pb-4",attrs:{"item":item,"data-index":index,"active":active,"size-dependencies":[
                //item.thumbNailUrl,
                //item.shortDescription,
              ]}},[_c('pad',{attrs:{"product":item,"statics":_vm.statics[item.pid],"occupancy":_vm.occupancy,"keyds":_vm.keyds,"brand":_vm.get_brand(item.pid.split('-')[1])}})],1)]}}],null,false,3394066562)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Siamo spiacenti! Non ci sono risultati per la ricerca effettuata."),_c('br'),_vm._v(" Prova a cambiare i criteri di ricerca. ")])
}]

export { render, staticRenderFns }