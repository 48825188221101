<template>
  <div class="filters">
    <b-row class="mt-4">
      <b-col>
        <div class="d-flex justify-content-between align-items-baseline align-items-sm-center">
          
          <div class="d-sm-flex align-items-center">
            <label class="d-flex m-0 mr-4">
              <vb-select
                @change="$event => update_order($event)"
                v-model="sort_internal"
                :vb_options="{
                  options: orders,
                  value: sort_internal,
                  //size: 'sm',
                }"
              />
            </label>
            <span class="d-block d-lg-inline mt-4 mt-sm-0" v-if="availability_is_known">
              <vb-checkbox
                  @change="update_filter($event, 'availability')"
                  :vb_options="{
                  switch: true,
                  text: 'Mostra in base al credito',
                  value: active_filters.filter_availability,
              }"/>
            </span>
          </div>

          <label class="filters__label" v-b-modal="'modal-search-filters'">
            Filtri
            <vb-icon
                class="ml-2"
                :name="'filter'"
                :color="'1a1a1a'"
                :size="18"
            />
          </label>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="filters__labels" :key="update_key">
          <div class="d-flex align-items-center justify-content-between">
            <div>
            <!--<span v-for="(filter, filter_index) in filters" v-bind:key="'btn_'+filter_index">-->
              <div v-for="(option,option_index) in filters.brand" v-bind:key="option_index">
                <div class="brand">
                  <vb-checkbox
                    class="ml-0"
                    @change="update_filter($event, 'brand')"
                    v-model="option.selected"
                    v-show="option.selected"
                    :vb_options="{
                      text: option.text + ' ('+option.amount+')',
                      value: option.selected,
                  }">
                    <template #default>
                      <div class="d-flex align-items-center">
                        <img :src="'assets/images/alpitour/liveat-brands/'+option.logo" />&nbsp;&nbsp;({{ option.amount }})
                      </div>
                    </template>
                  </vb-checkbox>
                </div>
            </div>

            <div v-for="(filter, filter_index) in {
                ...{ keyd: filters.keyd },
                ...{ accommodation: filters.accommodation },
                ...{ rating: filters.rating },
                ...{ airport: filters.airport },
                //...{ brand: filters.brand },            
            }" v-bind:key="'btn_'+filter_index">
              <vb-checkbox
                class="text-capitalize"
                v-for="(option,option_index) in filter" v-bind:key="'btn_'+option+option_index"
                v-show="option.selected"
                @change="update_filter($event, filter_index)"
                v-model="option.selected"
                :vb_options="{
                  text: (option.text + ' ('+option.amount+')').toLowerCase(),
                  value: option.selected,
              }"/>
            </div>
            <!--</span>-->
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!--<Transition name="fadeHeight" mode="out-in">-->
      <vb-modal :vb_options="{
        id: 'modal-search-filters',
        size: 'xl',
        title: 'Filtra i risultati',
        hide_footer: true,
      }">
        <b-container fluid :key="update_key">

          <b-row v-for="(filter, filter_index) in {
              ...{ brand: filters.brand },
            }" v-bind:key="filter_index">
            <b-col md="4" lg="3" xl="2" class="mb-3">
              <div :set="heading=get_heading(filter_index)" class="d-flex align-items-center">
                <vb-icon
                  :name="heading.icon"
                  :color="'1a1a1a'"
                  :size="24"
                />
                <span class="semibold ml-2" v-html="heading.title" />
              </div>
            </b-col>
            <b-col sm="6" md="4" lg="3" xl="2" class="mb-3" v-for="(option,option_index) in filter" v-bind:key="option_index">
              <div class="'custom-control custom-checkbox vb-check brand">
                <vb-checkbox
                  class="mr-4"
                  :class="{ disabled: option.amount==0 }"
                  @change="update_filter($event, filter_index)"
                  v-model="option.selected"
                  :vb_options="{
                    text: option.text + ' ('+option.amount+')',
                    value: option.selected,
                }">
                  <template #default>
                    <img :src="'assets/images/alpitour/liveat-brands/'+option.logo" />
                  </template>
                </vb-checkbox>
              </div>
            </b-col>
          </b-row>

          <div md="3" v-for="(filter, filter_index) in {
            ...{ keyd: filters.keyd },
            //...{ brand: filters.brand },
            ...{ accommodation: filters.accommodation },
            ...{ rating: filters.rating },
            ...{ airport: filters.airport },
          }" v-bind:key="filter_index">

            <hr class="d-block" />

            <b-row >
              <b-col>
                <div :set="heading=get_heading(filter_index)" class="d-flex align-items-center mb-3">
                  <vb-icon
                    :name="heading.icon"
                    :color="'1a1a1a'"
                    :size="20"
                  />
                  <span class="semibold ml-2">{{ heading.title }}</span>
                </div>
              </b-col>              
            </b-row>

            <b-row>
              <b-col>
                <div class="filters__column">
                  <vb-checkbox
                    v-for="(option,option_index) in filter" v-bind:key="option_index" :set_amount="amount=(option.amount>0 ? '('+option.amount+')' : '')" 
                    class="text-capitalize"
                    @change="update_filter($event, filter_index)"
                    v-model="option.selected"
                    :vb_options="{
                      text: (option.text + ' ' + amount).toLowerCase(),
                      value: option.selected,
                      disabled: option.amount==0,
                  }"/>
                </div>
              </b-col>
            </b-row>

          </div>
          
          <b-row>
            <b-col class="text-center text-lg-right mt-4">
              <vb-button
                @click="$bvModal.hide('modal-search-filters')"
                :vb_options="{
                  text: 'OK',
                }"/>
            </b-col>
          </b-row>
        
        </b-container>

        <div v-if="filtering" class="filtering">
          <loader />
        </div>
      </vb-modal>
    <!--</Transition>-->
  </div>
</template>

<script>
import { get_brand } from '@alpitour/resources'
export default {
  name: 'filters',

  props: {
    products: {
      type: Array,
      default: () => {
        return []
      }
    },
    filtered: {
      type: Array,
      default: () => {
        return []
      }
    },
    statics: {
      type: Object,
      default: () => {
        return {}
      }
    },
    active_filters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sort: {
      type: String,
      default: 'Ordina'
    },
    default_open: {
      type: Boolean,
      default: false,
    }
  },

  components: {
  },

  computed: {
    sort_internal: {
      get() {
        return this.sort
      },
      set() {
      }
    } 
  },

  data() {
    return {
      filtering: false,
      filters: [],
      orders: [
        {
          value: 'price|ASC',
          text: 'Prezzo crescente',
        },
        {
          value: 'price|DESC',
          text: 'Prezzo decrescente',
        },
        {
          value: '',
          text: 'Ordina per',
        },
      ],
      update_key: 0,
      availability_is_known: this.get_availability().is_known,
    }
  },

  created() {
  },

  mounted() {
    this.get_filters()
  },

  watch: {
    filtered(new_value, old_value) {
      if (new_value.length !== old_value.length) {
        this.get_filters()
        this.update_key = this.update_key+1
      }
    }
  },

  methods: {
    get_heading(key) {
      let heading = {
        icon: 'symbol_lb',
        title: '',
      }
      switch (key) {
        case 'keyd':
          heading.icon = 'pin'
          heading.title = 'Località'
          break
      
        case 'accommodation':
          heading.icon = 'bed'
          heading.title = 'Trattamento'
          break
      
        case 'rating':
          heading.icon = 'star'
          heading.title = 'Categoria'
          break
      
        case 'airport':
          heading.icon = 'airplane'
          heading.title = 'Aeroporto'
          break
      
        case 'brand':
          heading.icon = 'symbol_vv'
          heading.title = 'Brand'
          break
      }
      return heading
    },

    get_filters() {
      let filters = {
        keyd: [],
        accommodation: [],
        rating: [],
        airport: [],
        brand: [],
      }
      
      // ATTENZIONE:
      // per i keyds calcolo il totale su TUTTE le soluzioni possibili ( => products )
      // per tutti gli altri filtri calcolo il totale sulle soluzioni pre-filtrate ( => filtered )
      this.products.forEach(p => {
        if (filters.keyd.filter(k => { return p.keyd == k.value }).length == 0) {
          filters.keyd.push({
            text: Object.values(this.statics).filter(s => { return s.keyd == p.keyd })[0].city,
            value: p.keyd,
            amount: this.products.filter(k => { return p.keyd == k.keyd }).length,
            selected: this.active_filters.filter_keyd.includes(p.keyd),
          })
        }
        if (filters.accommodation.filter(k => { return p.accommodation == k.value }).length == 0) {
          filters.accommodation.push({
            text: p.accommodation,
            value: p.accommodation,
            amount: this.filtered.filter(k => { return p.accommodation == k.accommodation }).length,
            selected: this.active_filters.filter_accommodation.includes(p.accommodation),
          })
        }
        if (filters.rating.filter(k => { return p.rating == k.value }).length == 0) {
          filters.rating.push({
            text: p.rating,
            value: p.rating,
            amount: this.filtered.filter(k => { return p.rating == k.rating }).length,
            selected: this.active_filters.filter_rating.includes(p.rating),
          })
        }
        if (filters.airport.filter(k => { return p.departureAirportCode == k.value }).length == 0) {
          filters.airport.push({
            text: p.departureAirportDescription,
            value: p.departureAirportCode,
            amount: this.filtered.filter(k => { return k.departureAirportCode == p.departureAirportCode }).length,
            selected: this.active_filters.filter_airport.includes(p.departureAirportCode),
          })
        }
        if (filters.brand.filter(k => { return p.brand == k.value }).length == 0) {
          let brand = get_brand(p.brand)
          filters.brand.push({
            text: brand.name,
            value: p.brand,
            amount: this.filtered.filter(k => { return k.brand == p.brand }).length,
            selected: this.active_filters.filter_brand.includes(p.brand),
            logo: brand.logo,
          })
        }
      })

      for (const [key] of Object.entries(filters)) {
        // ordine alfabetico
        if (key == 'rating') {
          filters[key].sort((a,b) => {
            if (a.text == b.text) return 0
            return (a.text < b.text ? 1 : -1)
          })
        } else {
          filters[key].sort((a,b) => {
            if (a.text == b.text) return 0
            return (a.text >= b.text ? 1 : -1)
          })
        }
        // ordine di disponibilità
        /*else {
          filters[key].sort((a,b) => {
            if (a.amount == b.amount) return 0
            return (a.amount >= b.amount ? -1 : 1)
          })
        }*/
      }

      this.filters = filters
    },

    update_filter($event, key) {
      this.filtering = true
      let filters
      if (key == 'availability') {
        filters = $event
      }
      else if (this.filters[key] == undefined) {
        filters = []
      }
      else {
        filters = this.filters[key].filter(f => { return f.selected })
        filters = filters.map(f => {
          return f.value
        })
      }
      this.$emit('filter', {
        key: key,
        filters: filters,
      })
      // finto loader per rendere evidente l'azione di filtro (che è automatica al click) all'utente
      setTimeout(() => {
        this.filtering = false
      }, 1000);
    },

    update_order($event) {
      this.$emit('order', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.5s;
  max-height: 9999px;
  opacity:1;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
  max-height: 0px;
  opacity:0;
}
.filters {
  &__labels {
    display: flex;
    align-items: center;
    justify-content: right;

    .vb-check {
      margin-right: 16px;
      margin-left: 0;
    }
    * {
      display: flex;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin:0;
    padding-left:0 !important;
    .vb-icon {
      margin-right:1rem;
    }
  }

  &__column {
    column-count: 2;
    @include media-breakpoint-up(md) {
      column-count: 3;
    }
    @include media-breakpoint-up(lg) {
      column-count: 4;
    }
  }
}
.brand {
  padding-left:0;
  //padding-right:1.5rem;
  img {
    margin-left:0.15rem;
    height:16px;
    width:auto;
  }
}

.filtering {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background:rgba($white,0.9);
  z-index: 10;
}

.brand .custom-control.disabled {
  filter:grayscale(100);
  pointer-events: none;
}
</style>