var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filters"},[_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between align-items-baseline align-items-sm-center"},[_c('div',{staticClass:"d-sm-flex align-items-center"},[_c('label',{staticClass:"d-flex m-0 mr-4"},[_c('vb-select',{attrs:{"vb_options":{
                options: _vm.orders,
                value: _vm.sort_internal,
                //size: 'sm',
              }},on:{"change":$event => _vm.update_order($event)},model:{value:(_vm.sort_internal),callback:function ($$v) {_vm.sort_internal=$$v},expression:"sort_internal"}})],1),(_vm.availability_is_known)?_c('span',{staticClass:"d-block d-lg-inline mt-4 mt-sm-0"},[_c('vb-checkbox',{attrs:{"vb_options":{
                switch: true,
                text: 'Mostra in base al credito',
                value: _vm.active_filters.filter_availability,
            }},on:{"change":function($event){return _vm.update_filter($event, 'availability')}}})],1):_vm._e()]),_c('label',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-search-filters'),expression:"'modal-search-filters'"}],staticClass:"filters__label"},[_vm._v(" Filtri "),_c('vb-icon',{staticClass:"ml-2",attrs:{"name":'filter',"color":'1a1a1a',"size":18}})],1)])])],1),_c('b-row',[_c('b-col',[_c('div',{key:_vm.update_key,staticClass:"filters__labels"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',[_vm._l((_vm.filters.brand),function(option,option_index){return _c('div',{key:option_index},[_c('div',{staticClass:"brand"},[_c('vb-checkbox',{directives:[{name:"show",rawName:"v-show",value:(option.selected),expression:"option.selected"}],staticClass:"ml-0",attrs:{"vb_options":{
                    text: option.text + ' ('+option.amount+')',
                    value: option.selected,
                }},on:{"change":function($event){return _vm.update_filter($event, 'brand')}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":'assets/images/alpitour/liveat-brands/'+option.logo}}),_vm._v("  ("+_vm._s(option.amount)+") ")])]},proxy:true}],null,true),model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})],1)])}),_vm._l(({
              ...{ keyd: _vm.filters.keyd },
              ...{ accommodation: _vm.filters.accommodation },
              ...{ rating: _vm.filters.rating },
              ...{ airport: _vm.filters.airport },
              //...{ brand: filters.brand },            
          }),function(filter,filter_index){return _c('div',{key:'btn_'+filter_index},_vm._l((filter),function(option,option_index){return _c('vb-checkbox',{directives:[{name:"show",rawName:"v-show",value:(option.selected),expression:"option.selected"}],key:'btn_'+option+option_index,staticClass:"text-capitalize",attrs:{"vb_options":{
                text: (option.text + ' ('+option.amount+')').toLowerCase(),
                value: option.selected,
            }},on:{"change":function($event){return _vm.update_filter($event, filter_index)}},model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})}),1)})],2)])])])],1),_c('vb-modal',{attrs:{"vb_options":{
      id: 'modal-search-filters',
      size: 'xl',
      title: 'Filtra i risultati',
      hide_footer: true,
    }}},[_c('b-container',{key:_vm.update_key,attrs:{"fluid":""}},[_vm._l(({
            ...{ brand: _vm.filters.brand },
          }),function(filter,filter_index){return _c('b-row',{key:filter_index},[_c('b-col',{staticClass:"mb-3",attrs:{"md":"4","lg":"3","xl":"2"}},[_c('div',{staticClass:"d-flex align-items-center",attrs:{"set":_vm.heading=_vm.get_heading(filter_index)}},[_c('vb-icon',{attrs:{"name":_vm.heading.icon,"color":'1a1a1a',"size":24}}),_c('span',{staticClass:"semibold ml-2",domProps:{"innerHTML":_vm._s(_vm.heading.title)}})],1)]),_vm._l((filter),function(option,option_index){return _c('b-col',{key:option_index,staticClass:"mb-3",attrs:{"sm":"6","md":"4","lg":"3","xl":"2"}},[_c('div',{staticClass:"'custom-control custom-checkbox vb-check brand"},[_c('vb-checkbox',{staticClass:"mr-4",class:{ disabled: option.amount==0 },attrs:{"vb_options":{
                  text: option.text + ' ('+option.amount+')',
                  value: option.selected,
              }},on:{"change":function($event){return _vm.update_filter($event, filter_index)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('img',{attrs:{"src":'assets/images/alpitour/liveat-brands/'+option.logo}})]},proxy:true}],null,true),model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})],1)])})],2)}),_vm._l(({
          ...{ keyd: _vm.filters.keyd },
          //...{ brand: filters.brand },
          ...{ accommodation: _vm.filters.accommodation },
          ...{ rating: _vm.filters.rating },
          ...{ airport: _vm.filters.airport },
        }),function(filter,filter_index){return _c('div',{key:filter_index,attrs:{"md":"3"}},[_c('hr',{staticClass:"d-block"}),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex align-items-center mb-3",attrs:{"set":_vm.heading=_vm.get_heading(filter_index)}},[_c('vb-icon',{attrs:{"name":_vm.heading.icon,"color":'1a1a1a',"size":20}}),_c('span',{staticClass:"semibold ml-2"},[_vm._v(_vm._s(_vm.heading.title))])],1)])],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"filters__column"},_vm._l((filter),function(option,option_index){return _c('vb-checkbox',{key:option_index,staticClass:"text-capitalize",attrs:{"set_amount":_vm.amount=(option.amount>0 ? '('+option.amount+')' : ''),"vb_options":{
                    text: (option.text + ' ' + _vm.amount).toLowerCase(),
                    value: option.selected,
                    disabled: option.amount==0,
                }},on:{"change":function($event){return _vm.update_filter($event, filter_index)}},model:{value:(option.selected),callback:function ($$v) {_vm.$set(option, "selected", $$v)},expression:"option.selected"}})}),1)])],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"text-center text-lg-right mt-4"},[_c('vb-button',{attrs:{"vb_options":{
                text: 'OK',
              }},on:{"click":function($event){return _vm.$bvModal.hide('modal-search-filters')}}})],1)],1)],2),(_vm.filtering)?_c('div',{staticClass:"filtering"},[_c('loader')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }