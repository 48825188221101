<template>
  <div class="vb-result-view">
    <b-container fluid="lg">
      <booking-engine @search="search()"/>
      <section class="section section--loader" v-if="searching">
        <loader size="large" :show_timing_slot="true" />
      </section>
      <section class="section" v-if="!searching">
        <div v-if="last_search !== current_search">
          <b-alert show variant="warning">Attenzione, hai cambiato i criteri di ricerca ma non hai aggiornato i risultati. Clicca su Cerca.</b-alert>
          <br><br>
        </div>
        <results />
      </section>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { service_handler } from '@services'
import { custom_event, decode_parameters } from '@utilities'
import Results from '@alpitour/components/views/content-blocks/results.vue'

export default {
  name: 'results-view',

  components: {
    results: Results
  },

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
    // global state keys
    ...mapState([
      'searching',
    ]),    
    // module state keys
    ...mapState(
      'liveat', [
      'last_search',
      'current_search',
      'trigger_search',
    ]),
  },

  mounted() {
    if (this.trigger_search) this.search()
  },

  methods: {
    search() {
      this.$store.commit('searching', true)            // ridondante, lo lasciamo per sicurezza
      this.$store.commit('liveat/trigger_search', false)      // serve altrimenti la vista va in loop sul search()

      let timestamp_start = (new Date()).getTime(),
        search_params = decode_parameters(this.last_search)

      service_handler(
        'search',
        search_params,
      ).then(
        (success) => {
          let products = success.data.data.products.sort(function (a, b) {
            return a.keyd.localeCompare(b.keyd)
              || a.pid.localeCompare(b.pid)
              || a.departureAirportCode.localeCompare(b.departureAirportCode)
              || a.departureDate.localeCompare(b.departureDate)
              || a.room.localeCompare(b.room)
              || a.accommodation.localeCompare(b.accomodation)
              || a.price.localeCompare(b.price)
          })

          let statics = success.data.data.statics
          let keyds = success.data.data.keyds

          // delay controllato per evitare ricerche troppo veloci
          let gap = (new Date()).getTime() - timestamp_start,
            min_gap = 1500,
            delay = gap < min_gap ? (min_gap - gap) : 0
          setTimeout(() => {
            this.$store.commit('liveat/products', products)
            this.$store.commit('liveat/statics', statics)
            this.$store.commit('liveat/keyds', keyds)
            this.$store.commit('searching', false)
          }, delay)

          custom_event('search_keyd', search_params.keyd, (success.data.data.products.length), 'results_count')
          custom_event('search_date', search_params.departure_date, (success.data.data.products.length), 'results_count')
          custom_event('search_nights', search_params.nights, (success.data.data.products.length), 'results_count')
          custom_event('search_occupancy', success.data.data.occupancy, (success.data.data.products.length), 'results_count')
        },
        () => {
          this.$store.commit('searching', false)
        }
      )      
    }
  }
}
</script>

<style lang="scss" scoped>
</style>